import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

interface ConfirmDialogProps {
  open: boolean,
  onClose: () => void,
  title: string,
  content: React.ReactNode,
  cancelText: string,
  confirmText: string,
  onConfirm: () => void
  hasDate?: boolean;
  type?: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, onClose, title, content, cancelText, confirmText, onConfirm, hasDate, type }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {cancelText}
        </Button>
        <Button
          disabled={type === "reschedule" && !hasDate}
          id="confirm-btn"
          onClick={onConfirm}
          color="primary"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog