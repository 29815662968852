import React from "react";
import {
  Close,
  Delete,
  OpenInBrowser,
  OpenInNew,
  PauseSharp,
  PlayArrow,
  Share,
} from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardHeader,
  Grid,
  IconButton,
  Container,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { useMonitor, useSelectedOrganization, useTranslations } from "hooks";
import { Link } from "react-router-dom";
import NoScheduledImg from "image/no-scheduled.png";
import { getTranslation } from "common";

const Scheduled = () => {
  const selectedOrganization = useSelectedOrganization();
  const translations = useTranslations();
  const {
    monitors,
    deleteTimerStartEvent,
    switchEnableTimerStartEvent,
    stopNextTimerStartEvent,
  } = useMonitor(selectedOrganization?.id);

  return (
    <Box flex={1}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {getTranslation(translations, "menu.left.timer")}
      </Typography>
      {monitors?.length <= 0 && (
        <Container
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
          maxWidth="md"
        >
          <Box
            component="img"
            height="50dvh"
            width="auito"
            sx={{ borderRadius: 9999 }}
            src={NoScheduledImg}
            alt="no scheduled process"
          />
          <Typography fontWeight="bold">
            {getTranslation(translations, "no_data.scheduled")}
          </Typography>
          <Typography>
            {getTranslation(translations, "no_data.scheduled.description")}
          </Typography>
        </Container>
      )}
      {monitors?.length > 0 && (
        <Grid container spacing={2}>
          {monitors.map((timer) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={timer.id}>
              <Card
                elevation={3}
                sx={{
                  height: "auto",
                  minHeight: 125,
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "15px",
                }}
              >
                <CardHeader
                  title={timer.processmodel_name}
                  subheader={dayjs(timer.starttime).format("DD/MM/YYYY HH:mm")}
                />
                <CardActions>
                  {timer.idtype !== 3 && (
                    <Tooltip
                      title={getTranslation(
                        translations,
                        "events.delete_timers"
                      )}
                    >
                      <IconButton onClick={() => deleteTimerStartEvent(timer)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={getTranslation(translations, "events.pause_timers")}
                  >
                    <IconButton
                      onClick={() => switchEnableTimerStartEvent(timer)}
                    >
                      {timer.enabled ? <PauseSharp /> : <PlayArrow />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={getTranslation(
                      translations,
                      "events.skip_next_timer"
                    )}
                  >
                    <IconButton onClick={() => stopNextTimerStartEvent(timer)}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                  <Link to={`/dashboard/process/edit/${timer.idprocessmodel}`}>
                    <IconButton>
                      <OpenInNew />
                    </IconButton>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Scheduled;
